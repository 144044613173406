export default [
  {
    prop: 'id',
    label: '#',
    type: 'id'
  },
  {
    prop: 'code',
    label: 'Code',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'title',
    label: 'Tiêu đề',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'end_at',
    label: 'Ngày hết hạn',
    minWidth: 150,
    type: 'datetime'
  },
  {
    prop: 'price',
    label: 'Giảm giá',
    minWidth: 80,
    type: 'number'
  },
  {
    prop: 'rest',
    label: 'Còn lại',
    minWidth: 80,
    type: 'text',
    ignoreSearch: true
  }
]
