<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="coupons" :actions="actions" />
        </div>
      </div>
    </div>

    <el-dialog title="Import file" :visible.sync="is_show_modal_import" width="50%">
      <div class="row">
        <div class="col-12 text-center">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleUploadChange"
            :on-remove="handleRemoveUpload"
            accept=".xlsx,.xls"
            :auto-upload="false"
            :multiple="false"
            :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Chọn file</div>
            <div class="el-upload__tip" slot="tip">Hỗ trợ file xls, xlsx</div>
          </el-upload>
          <div>
            <a href="https://irace.vn/races/files/import_coupon_template.xlsx" target="_blank">Tải file mẫu</a>
          </div>
          <div class="import-status" v-if="is_show_import_status">
            <el-tag type="primary">Tổng: {{ import_total }}</el-tag>
            <el-tag type="success">Thành công: {{ import_success }}</el-tag>
            <el-tag type="danger">Thất bại: {{ import_total - import_success }}</el-tag>
            <div style="margin-top: 10px;">
              <el-tag v-if="import_errors.length" v-for="(item, index) of import_errors" :key="index" type="warning" size="small">{{ item }}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="closeModalImport">Hủy</el-button>
        <el-button size="sm" type="primary" @click="submitUpload">Import</el-button>
      </span>
    </el-dialog>

    <loading :active.sync="loading"></loading>

  </div>
</template>

<script>
  import { MessageBox, Message, Dialog, Upload, Button, Tag } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import couponSchemas from './coupon-schemas'
  import dtHelper from 'src/helpers/datatable'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default{
    components: {
      MyTable,
      MyFilter,
      Loading,
      ElDialog: Dialog,
      ElUpload: Upload,
      ElButton: Button,
      ElTag: Tag
    },
    computed: {
      coupons() {
        let rows = this.$store.state.coupons;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data() {
      return {
        loading: false,
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(couponSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(couponSchemas),
        is_show_modal_import: false,
        is_show_import_status: false,
        fileList: [],
        import_total: 0,
        import_success: 0,
        import_errors: []
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchCoupons');
      this.$store.dispatch('setPageTitle', 'Quản lý Coupon');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Import',
        type: 'info',
        icon: 'el-icon-upload',
        callback: this.showModalImport
      }, {
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.createCoupon
      }]);
    },
    methods: {
      showModalImport() {
        this.is_show_modal_import = true;
        this.is_show_import_status = false;
      },

      handleUploadChange(file, fileList) {
        this.fileList = fileList.slice(-1);
      },

      handleRemoveUpload(file, fileList) {
        this.fileList = [];
      },

      closeModalImport() {
        this.is_show_modal_import = false;
        this.is_show_import_status = false;
        this.fileList = [];
      },

      submitUpload() {
        this.is_show_import_status = false;
        this.import_success = 0;
        this.import_total = 0;
        this.import_errors = [];

        if (this.fileList.length == 0) {
          Message({
            type: 'error',
            message: 'Vui lòng chọn file'
          });
          return false;
        }
        this.loading = true;
        const formData = new FormData();
        formData.append('file_import', this.fileList[0].raw);
        this.$store.dispatch('importCoupon', formData).then(res => {
          this.is_show_import_status = true;
          this.import_total = res.total;
          this.import_success = res.save;
          this.import_errors = res.errors;
          this.loading = false;
        });
      },

      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },

      edit(index, row){
        this.$router.push('/coupons/' + row.id);
      },

      createCoupon() {
        this.$router.push('/coupons/create');
      },

      remove(index, row) {
        MessageBox.confirm('Xóa coupon?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeCoupon', row.id).then(res => {
            if (res.success) {
              Message({
                type: 'success',
                message: 'Đã xóa'
              });

              let index = this.coupons.findIndex(x => x.id === row.id);
              this.coupons.splice(index, 1);

            } else {
              if (res.errors) {
                Message({
                  type: 'error',
                  message: res.errors
                });
              } else {
                Message({
                  type: 'error',
                  message: 'Có lỗi xảy ra, xin vui lòng thử lại'
                });
              }
            }
          });
        }, error => {

        });
      }
    },

    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>

